import React from "react";

const containerStyle = {
  margin: '8px',
  padding: '2px 4px',
  border: '1px solid'
};

const linkStyle = {
  fontWeight: '700',
  color: '#fff',
  textDecoration: 'none',
};

export function NoWalletDetected() {
  return (
    <div style={containerStyle}>
      <a
        style={linkStyle}
        href="http://metamask.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        MetaMask
      </a>
    </div>
  );
}
