import React from "react";

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '2px 4px',
  backgroundColor: '#000',
};

const hashStyle = {
  fontSize: '12px'
};

export function WaitingForTransactionMessage({ txHash }) {
  return (
    <div style={containerStyle}>
      <div>Waiting for transaction...</div>
      <div style={hashStyle}>{txHash}</div>
    </div>
  );
}
