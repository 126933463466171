import React from "react";

import { NetworkErrorMessage } from "./NetworkErrorMessage";

const containerStyle = {
  margin: '8px',
  padding: '2px 4px',
  outline: '1px solid'
};

const buttonStyle = {
  cursor: 'pointer'
};

export function ConnectWallet({ connectWallet, networkError, dismiss }) {
  // return (
  //   <div style={containerStyle}>
  //     {/* Metamask network should be set to Localhost:8545. */}
  //     {networkError && (
  //       <NetworkErrorMessage 
  //         message={networkError} 
  //         dismiss={dismiss} 
  //       />
  //     )}
  //     {!networkError && (
  //       <div
  //         style={buttonStyle}
  //         onClick={connectWallet}
  //       >
  //         SYNC
  //       </div>
  //     )}
  //   </div>
  return (
    <div style={containerStyle}>
      <div style={buttonStyle}>
        SOLD OUT
      </div>
    </div>
  );
}
