import React from "react";

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '2px 4px',
  backgroundColor: '#000',
};

const textStyle = {
  margin: '10px 8px 8px',
};

const buttonStyle = {
  margin: '8px',
  height: '18px',
  display: 'inline',
  padding: '2px 4px',
  outline: '1px solid',
  cursor: 'pointer'
};

export function TransactionErrorMessage({ message, dismiss }) {
  return (
    <div style={containerStyle}>
      <div style={textStyle}>
        Error sending transaction: {message.split(';')[0]}...
      </div>
      <div
        style={buttonStyle}
        data-dismiss="alert"
        aria-label="Close"
        onClick={dismiss}
      >
        CLOSE
      </div>
    </div>
  );
}
