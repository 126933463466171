import React from "react";

import { ethers } from "ethers";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import RainbowGridArtifact from "../contracts/rainbow-grid.json";
import contractAddress from "../contracts/contract-address.json";

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";

import { Item } from "./Item";

const containerStyle = {
  
};

const headerStyle = {
  width: '100vw',
  minWidth: '320px',
  height: '68px',
  position: 'fixed',
  top: '0px',
  left: '0px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'rgb(0,0,0)',
  background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 25%, rgba(0,255,0,1) 50%, rgba(0,255,255,1) 75%, rgba(0,0,255,1) 100%)',
};

const menuStyle = {
  margin: '8px',
};

const titleStyle = {
  height: '16px',
  marginBottom: '2px',
  color: '#fff',
  fontSize: '12px'
};

const titleBackground = {
  padding: '1px 2px',
  backgroundColor: '#000',
};

const linkStyle = {
  color: '#fff',
  textDecoration: 'none'
}

const walletStyle = {
  margin: '8px',
  padding: '2px 4px',
  outline: '1px solid',
};

const gridStyle = {
  marginTop: '68px',
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
};

const messageStyle = {
  width: '50vw',
  maxWidth: '90vw',
  overflowX: 'auto',
  height: 'auto',
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: '1000',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#000',
  outline: '1px solid #fff',
};

// This is the Hardhat Network id, you might change it in the hardhat.config.js
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
// const HARDHAT_NETWORK_ID = '31337';

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

// This component is in charge of doing these things:
//   1. It connects to the user's wallet
//   2. Initializes ethers and the Token contract
//   3. Polls the user balance to keep it updated.
//   4. Transfers tokens by sending transactions
//   5. Renders the whole application
//
// Note that (3) and (4) are specific of this sample application, but they show
// you how to keep your Dapp and contract's state in sync,  and how to send a
// transaction.
export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      // The info of the token (i.e. It's Name and symbol)
      tokenData: { name: 'Rainbow Grid, Kim Asendorf', symbol: 'ASDFRG' },
      availableTokens: [ false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false ],
      tokenOwners: [],
      // The user's address and balance
      selectedAddress: undefined,
      balanceAsdfrg: undefined,
      balanceEth: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
    };

    this.state = this.initialState;
  }
  
  componentDidMount() {
    console.log('Rainbow Grid');
  }

  render() {
    return (
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div style={menuStyle}>
            <div style={titleStyle}>
              <span style={titleBackground}>{this.state.tokenData.name} ({this.state.tokenData.symbol})</span></div>
            <div style={titleStyle}>
              <span style={titleBackground}>
                <a
                  style={linkStyle}
                  href='https://opensea.io/collection/rainbowgrid'
                  target="_blank"
                  rel="noopener noreferrer"
                >Collection on OpenSea</a>
              </span>
            </div>
            <div style={titleStyle}>
              <span style={titleBackground}>
                <a
                  style={linkStyle}
                  href='https://etherscan.io/address/0xF3D751855b21bf0b17C229106f6C428C838C4917'
                  target="_blank"
                  rel="noopener noreferrer"
                >Contract on Etherscan</a>
              </span>
            </div>
          </div>
          <div>
            {window.ethereum === undefined && 
              <NoWalletDetected />
            }
            {window.ethereum && !this.state.selectedAddress &&
              <ConnectWallet 
                connectWallet={() => this._connectWallet()} 
                networkError={this.state.networkError}
                dismiss={() => this._dismissNetworkError()}
              />
            }
            {window.ethereum && this.state.selectedAddress &&
              <div style={walletStyle}><b>{this.state.selectedAddress}</b></div>
            }
          </div>
        </div>

        {/* 
          Sending a transaction isn't an immidiate action. You have to wait
          for it to be mined.
          If we are waiting for one, we show a message here.
        */}
        {this.state.txBeingSent && (
          <div style={messageStyle}>
            <WaitingForTransactionMessage txHash={this.state.txBeingSent} />
          </div>
        )}

        {/* 
          Sending a transaction can fail in multiple ways. 
          If that happened, we show a message here.
        */}
        {this.state.transactionError && (
          <div style={messageStyle}>
            <TransactionErrorMessage
              message={this._getRpcErrorMessage(this.state.transactionError)}
              dismiss={() => this._dismissTransactionError()}
            />
          </div>
        )}

        <div style={gridStyle}>
          {this.state.availableTokens.map((value, index) => {
            return <Item
              key={index}
              mintToken={(tokenId) => {
                this._mintToken(tokenId)
              }}
              tokenId={index}
              tokenSymbol={this.state.tokenData.symbol}
              isAvailable={value}
              tokenOwner={this._getTokenOwner(index)}
            />
          })}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    // We poll the user's balance, so we have to stop doing that when Dapp
    // gets unmounted
    this._stopPollingData();
  }

  async _connectWallet() {
    // This method is run when the user clicks the Connect. It connects the
    // dapp to the user's wallet, and initializes it.

    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    const [selectedAddress] = await window.ethereum.enable();

    // Once we have the address, we can initialize the application.

    // First we check the network
    if (!this._checkNetwork()) {
      return;
    }

    this._initialize(selectedAddress);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state 
      if (newAddress === undefined) {
        return this._resetState();
      }
      
      this._initialize(newAddress);
    });
    
    // We reset the dapp state if the network is changed
    window.ethereum.on("networkChanged", ([networkId]) => {
      this._stopPollingData();
      this._resetState();
    });
  }

  _initialize(userAddress) {
    // This method initializes the dapp

    // We first store the user's address in the component's state
    this.setState({
      selectedAddress: userAddress,
    });

    // Then, we initialize ethers, fetch the token's data, and start polling
    // for the user's balance.

    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    this._intializeEthers();
    this._getTokenData();
    this._startPollingData();
  }

  async _intializeEthers() {
    // We first initialize ethers by creating a provider using window.ethereum
    this._provider = new ethers.providers.Web3Provider(window.ethereum);

    // When, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    this._rainbowGrid = new ethers.Contract(
      contractAddress.RainbowGrid,
      RainbowGridArtifact.abi,
      this._provider.getSigner(0)
    );
  }

  // The next two methods are needed to start and stop polling data. While
  // the data being polled here is specific to this example, you can use this
  // pattern to read any data from your contracts.
  //
  // Note that if you don't need it to update in near real time, you probably
  // don't need to poll it. If that's the case, you can just fetch it when you
  // initialize the app, as we do with the token data.
  _startPollingData() {
    this._pollDataInterval = setInterval(() => this._updateBalance(), 1000);

    // We run it once immediately so we don't have to wait for it
    this._updateBalance();
  }

  _stopPollingData() {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }

  // The next two methods just read from the contract and store the results
  // in the component state.
  async _getTokenData() {
    const name = await this._rainbowGrid.name();
    const symbol = await this._rainbowGrid.symbol();
    // const baseUri = await this._rainbowGrid.baseURI();
    // const baseUriGate = 'https://ipfs.io/ipfs/' + baseUri.substr(7);
    // const meta = []
    // for (let i=0; i<54; i++) {
    //   const response = await fetch(baseUriGate + i + '.json');
    //   if (!response.ok)
    //     throw new Error(response.statusText);
    //     const json = await response.json();
    //     //console.log(json.name);
    //     //console.log(json.image);
    //     console.log(json.animation_url);
    // }

    this.setState({ tokenData: { name, symbol } });
  }

  async _updateBalance() {
    const balanceAsdfrg = await this._rainbowGrid.balanceOf(this.state.selectedAddress);

    const balanceEth = await this._provider.getBalance(this.state.selectedAddress);

    const availableTokens = await this._rainbowGrid.getAvailableTokens();

    const tokenOwners = [];

    for (let i = 0; i < 54; i++) {
      if (!availableTokens[i]) {
        try {
          const owner = await this._rainbowGrid.ownerOf(i);
          tokenOwners.push({
            tokenId: i,
            owner: owner
          });
        } catch (err) {
          console.error(i, err);
        }
      }
    }

    this.setState({ balanceAsdfrg, balanceEth, availableTokens, tokenOwners });
  }

  // This method sends an ethereum transaction to transfer tokens.
  // While this action is specific to this application, it illustrates how to
  // send a transaction.
  async _mintToken(tokenId) {
    // Sending a transaction is a complex operation:
    //   - The user can reject it
    //   - It can fail before reaching the ethereum network (i.e. if the user
    //     doesn't have ETH for paying for the tx's gas)
    //   - It has to be mined, so it isn't immediately confirmed.
    //     Note that some testing networks, like Hardhat Network, do mine
    //     transactions immediately, but your dapp should be prepared for
    //     other networks.
    //   - It can fail once mined.
    //
    // This method handles all of those things, so keep reading to learn how to
    // do it.

    try {
      // If a transaction fails, we save that error in the component's state.
      // We only save one such error, so before sending a second transaction, we
      // clear it.
      this._dismissTransactionError();

      // We send the transaction, and save its hash in the Dapp's state. This
      // way we can indicate that we are waiting for it to be mined.
      const tx = await this._rainbowGrid.mint(tokenId, {
        value: ethers.utils.parseEther("0.25"),
      });
      this.setState({ txBeingSent: tx.hash });

      // We use .wait() to wait for the transaction to be mined. This method
      // returns the transaction's receipt.
      const receipt = await tx.wait();

      // The receipt, contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that made the transaction fail when it
        // was mined, so we throw this generic one.
        throw new Error("Transaction failed");
      }

      // If we got here, the transaction was successful, so you may want to
      // update your state. Here, we update the user's balance.
      await this._updateBalance();
    } catch (error) {
      // We check the error code to see if this error was produced because the
      // user rejected a tx. If that's the case, we do nothing.
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      // Other errors are logged and stored in the Dapp's state. This is used to
      // show them to the user, and for debugging.
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      // If we leave the try/catch, we aren't sending a tx anymore, so we clear
      // this part of the state.
      this.setState({ txBeingSent: undefined });
    }
  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  // This method checks if Metamask selected network is Localhost:8545 
  _checkNetwork() {
    console.log(window.ethereum.networkVersion);
    //console.log(HARDHAT_NETWORK_ID);
    //if (window.ethereum.networkVersion === HARDHAT_NETWORK_ID) {
      return true;
    //}

    // this.setState({ 
    //   networkError: 'Please connect Metamask to Localhost:8545'
    // });

    // return false;
  }

  _getTokenOwner(tokenId) {
    let tokenOwner = this.state.tokenOwners.find(to => to.tokenId === tokenId)
    return tokenOwner ? tokenOwner.owner : undefined
  }
}
